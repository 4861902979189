<template>
  <v-container>
    <!-- 客戶分級 -->
    <section>
      <div class="content">
        <div class="titel">客戶分級</div>
        <div class="note_title">請選擇麥當勞的分級</div>
        <div class="text-center">
          <v-select v-model="location" :items="locations" label=""></v-select>
          <v-menu :location="location">
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-row align="center" justify="center">
        <v-col>
          <v-btn class="btn btn01">稍後再分級</v-btn>
        </v-col>
        <v-col>
          <v-btn class="btn btn02">確認</v-btn>
        </v-col>
      </v-row>
    </section>

    <!--  客戶分級設定01 -->
    <section>
      <div class="content">
        <div class="titel">客戶分級設定</div>
        <v-btn class="btn level_btn btn_graybg my-2">
          <div class="level_title">一般客戶</div>
          <div class="level_icon">
            <i
              aria-hidden="true"
              class="v-icon notranslate fas fa-caret-right theme--light"
            ></i>
          </div>
        </v-btn>

        <v-btn class="btn level_btn btn_graybg my-2">
          <div class="level_title">黃金客戶</div>
          <div class="level_icon">
            <i
              aria-hidden="true"
              class="v-icon notranslate fas fa-caret-right theme--light"
            ></i>
          </div>
        </v-btn>

        <v-btn class="btn level_btn btn_graybg my-2">
          <div class="level_title">白金客戶</div>
          <div class="level_icon">
            <i
              aria-hidden="true"
              class="v-icon notranslate fas fa-caret-right theme--light"
            ></i>
          </div>
        </v-btn>

        <v-btn class="btn level_btn btn_graybg my-2">
          <div class="level_title">鑽石客戶</div>
          <div class="level_icon">
            <i
              aria-hidden="true"
              class="v-icon notranslate fas fa-caret-right theme--light"
            ></i>
          </div>
        </v-btn>

        <div class="add_btn_content">
          <v-btn class="btn btn_add">
            <div class="add_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-add-line theme--light"
              ></i>
            </div>
            <div class="add_title">新增客戶分級</div>
          </v-btn>
        </div>
      </div>
    </section>

    <!--  客戶分級設定02 -->
    <section>
      <div class="content">
        <div class="titel">客戶分級設定</div>
        <div class="alert btn_graybg my-2">
          <div class="alert_title">一般客戶</div>
          <v-col>
            <v-btn class="btn btn_red">刪除分級</v-btn>
          </v-col>
        </div>

        <div class="alert alert2 my-2">
          <div class="alert_title">麥當勞</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>

        <div class="alert alert2 my-2">
          <div class="alert_title">五十嵐－府中店</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>

        <div class="alert alert2 my-2">
          <div class="alert_title">亞米馥－中和店</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>

        <div class="add_btn_content">
          <v-btn class="btn btn_add">
            <div class="add_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-add-line theme--light"
              ></i>
            </div>
            <div class="add_title">新增餐廳至一般客戶</div>
          </v-btn>
        </div>
      </div>
      <v-row align="center" justify="center">
        <v-col>
          <v-btn class="btn btn01" size="x-large">返回</v-btn>
        </v-col>
        <v-col>
          <v-btn class="btn btn02">儲存</v-btn>
        </v-col>
      </v-row>
    </section>
    <!--  客戶分級設定03 -->
    <section>
      <div class="content">
        <div class="titel">客戶分級設定</div>
        <div class="alert btn_graybg my-2">
          <div class="alert_title">一般客戶</div>
          <v-col>
            <v-btn class="btn btn_red">刪除分級</v-btn>
          </v-col>
        </div>

        <div class="alert alert2 my-2">
          <div class="alert_title">麥當勞</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>
        <div class="alert alert2 my-2">
          <div class="alert_title">五十嵐－府中店</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>
        <div class="alert alert2 my-2">
          <div class="alert_title">亞米馥－中和店</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>
        <div class="alert alert2 my-2">
          <div class="alert_title">三顧茅廬-板橋四川店</div>
          <v-col>
            <v-btn class="alert2_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-delete-bin-2-fill theme--light"
              ></i>
            </v-btn>
          </v-col>
        </div>

        <div class="add_btn_content">
          <v-btn class="btn btn_add">
            <div class="add_icon">
              <i
                aria-hidden="true"
                class="v-icon notranslate ri ri-add-line theme--light"
              ></i>
            </div>
            <div class="add_title">新增餐廳至一般客戶</div>
          </v-btn>
        </div>
      </div>
      <v-row align="center" justify="center">
        <v-col>
          <v-btn class="btn btn01" size="x-large">返回</v-btn>
        </v-col>
        <v-col>
          <v-btn class="btn btn02">儲存</v-btn>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    // items: [
    //   { title: 'Click Me' },
    //   { title: 'Click Me' },
    //   { title: 'Click Me' },
    //   { title: 'Click Me 2' },
    // ],
    locations: ["一般客戶", "黃金客戶", "白金客戶", "鑽石客戶"],
    location: "一般客戶",
  }),
};
</script>

<style lang="scss">
.content {
  width: 100%;
  min-height: calc(100vh - 174px);
}

.titel {
  font-size: 24px;
  color: #000;
  margin: 30px 0;
}

.note_title {
  font-size: 16px;
  color: #122a47;
}

.btn {
  width: 100%;
  box-shadow: none !important;
}

.level_btn {
  width: 100%;
  min-height: 57px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .level_title {
    font-size: 16px;
    width: 100%;
    text-align: left;
  }

  .level_icon {
    min-width: 48px;
    min-height: 36px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn_graybg {
  background: #f6f8fa;
}

.btn_whitebg {
  border: 1px solid #f1f1f1;
  background: #fff !important;
}

.alert {
  width: 100%;
  min-height: 57px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .alert_title {
    width: 100%;
    text-align: left;
  }

  .btn_red {
    background: #ed5550 !important;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 10px;
    min-width: 93px;
    min-height: 36px;
  }
}

.alert2 {
  border: 1px solid #f1f1f1;

  .alert2_icon {
    min-width: 48px;
    min-height: 36px;
    border-radius: 10px;
    background: #f6f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;

    .theme--light.v-icon {
      font-size: 25px;
      color: #ff7d00;
    }
  }
}

.btn01 {
  color: #ff7d00 !important;
  background: #fff !important;
  border: 1px solid #ff7d00 !important;
}

.btn02 {
  color: #fff !important;
  background: #ff7d00 !important;
  border: 1px solid #ff7d00 !important;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  border: none;
}

.v-select__slot {
  position: relative;
  align-items: center;
  display: flex;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  border: 1px solid #000;
  padding: 0 10px;
}

.add_btn_content {
  width: 100%;

  .btn_add {
    width: 100%;
    border: 1px solid #ff7d00;
    background: #fff !important;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 67px;

    .add_icon {
      .theme--light.v-icon {
        font-size: 25px;
        color: #ff7d00;
      }
    }

    .add_title {
      font-size: 16px;
      color: #ff7d00;
    }
  }

  .add_btn_box {
    width: 100%;
    border: 1px solid #ff7d00;
    background: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>